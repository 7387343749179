import { IoPersonCircleOutline } from "react-icons/io5";
import { ImExit } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const MenuTopo = () => {
  const navigate = useNavigate();
  const [data, setDados] = useState([]); 
  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id_user');
  const [, setModalSenha] = useState(false);

  useEffect(() => {
    fetch(`https://suapuno.app:8080/api/usuarios/${id}/`, {
      method: 'GET',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro ao acessar a API');
      }
      return response.json();
    })
    .then(data => {
      setDados(data);
    })
    .catch(error => {
      console.error('Erro ao buscar dados:', error);
    });
  }, [id, token]);

  return (
    <div className="flex flex-row bg-white px-3 justify-end gap-5">
      <div className="flex items-center">
        <IoPersonCircleOutline onClick={() => setModalSenha(true)} color="" size={40}/>
        <h1 className="ml-2 font-bold">{data.username}</h1>
      </div>
      <div onClick={() => {navigate('/'); localStorage.clear();}}>
        <div className="mt-4">
          <ImExit size={25} color="red" className="mt-5"/>
          <h2 className="text-white text-sm">Sair</h2>
        </div>
      </div>
    </div>
  );
}

export default MenuTopo;