import React, { useEffect, useState } from 'react';
import { FaPencilAlt, FaSearch } from 'react-icons/fa';
import { PiPlus, PiTrash } from 'react-icons/pi';

const Entidade = () => {
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [termoBusca, setTermoBusca] = useState('');
    const token = localStorage.getItem('token');
    const [entidades, setEntidades] = useState([]);
    const [novaEntidade, setnovaEntidade] = useState('');
    const [novaDescricao, setnovaDescricao] = useState('');
    const [itensPorPagina, setItensPorPagina] = useState(5);
    const [modalEditar, setModalEditar] = useState(null);

    const calcularRows = (texto, limite) => {
        if (texto.split('\n').length < 2) {
            return Math.min((texto.length / 50) + 1, limite);
        } else {
            return Math.min(texto.split('\n').length, limite);
        }
    };

    useEffect(() => {
        const calcularItensPorPagina = () => {
            const alturaJanela = window.innerHeight;
            const alturaElementosFixos = 200;
            const alturaNovaEntidade = calcularRows(novaEntidade, 5) * 20; // 20px por linha
            const alturaNovaDescricao = calcularRows(novaDescricao, 5) * 20; // 20px por linha
            const alturaDisponivel = alturaJanela - alturaElementosFixos - alturaNovaEntidade - alturaNovaDescricao;
            const alturaItem = 70; // Altura aproximada de um item na lista
            const novosItensPorPagina = Math.floor(alturaDisponivel / alturaItem);
            setItensPorPagina(Math.max(novosItensPorPagina, 1));
        };

        calcularItensPorPagina();
        window.addEventListener('resize', calcularItensPorPagina);

        return () => window.removeEventListener('resize', calcularItensPorPagina);
    }, [novaEntidade, novaDescricao]);

    const fetchDados = async () => {
        try {
            const entidades = await fetch('https://suapuno.app:8080/api/assistente-virtual/entidades/', {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`
                }
            });
            if (entidades.ok) {
                const resposta = await entidades.json();
                setEntidades(resposta.reverse());
            } else {
                console.log('Api não retornou o ok:', entidades.status);
            }
        } catch (error) {
            console.log('Erro ao tentar obter entidades.', error);
        }
    };

    useEffect(() => {
        fetchDados();
    // eslint-disable-next-line
    }, [token]);

    const substituirSimbolo = (texto) => {
        if (texto.startsWith('$')) {
            return '@' + texto.slice(1);
        }
        return texto;
    };

    const itensModificados = entidades.map(item => ({
        ...item,
        entidade: substituirSimbolo(item.entidade),
    }));

    const itensFiltrados = itensModificados.filter(item => 
        item.entidade.toLowerCase().includes(termoBusca.toLowerCase()) || 
        item.descricao.toLowerCase().includes(termoBusca.toLowerCase())
    );

    const adicionarItem = () => {
        if (novaEntidade.trim() && novaDescricao.trim()) {
            const novoItem = {
                entidade: novaEntidade,
                descricao: novaDescricao,
            };
            fetch(`https://suapuno.app:8080/api/assistente-virtual/entidades/`, {   
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify(novoItem)
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erro na requisição: ' + response.statusText);
                } else {
                    fetchDados();
                    setnovaEntidade('');
                    setnovaDescricao('');
                }
            })
            .catch(error => console.log('Erro ao adicionar item.', error));
        }
    };

    const removerItem = (id_entidade) => {
        fetch(`https://suapuno.app:8080/api/assistente-virtual/entidades/${id_entidade}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Erro na requisição: ' + response.statusText);
            }
        })
        setEntidades(entidades.filter(item => item.id_entidade !== id_entidade));
    };

    const paginasVisiveis = () => {
        const paginas = [];
        if (paginaAtual - 1 > 0) {
            paginas.push(paginaAtual - 1);
        }
        paginas.push(paginaAtual);
        if (itensFiltrados.slice((paginaAtual - 1) * itensPorPagina, paginaAtual * itensPorPagina).length === itensPorPagina) {
            paginas.push(paginaAtual + 1);
        }
        return paginas;
    };

    const handleInputChange = (field, value) => {
        setModalEditar(prev => ({ ...prev, [field]: value }));
    };

    const atualizaEntidade = () => {
        const { id_entidade, entidade, descricao } = modalEditar;
        const novoItem = { entidade, descricao };
        fetch(`https://suapuno.app:8080/api/assistente-virtual/entidades/${id_entidade}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify(novoItem)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Erro na requisição: ' + response.status);
            } else {
                fetchDados();
                fecharModalTexto();
            }
        })
        .catch(error => {
            console.log('Erro ao salvar alterações.', error);
        });
    };

    const abrirModalTexto = (item) => {
        setModalEditar(item);
    };

    const fecharModalTexto = () => {
        setModalEditar(null);
    };

    return (
        <div className='flex h-fit'>
            {modalEditar &&
                <div className='fixed inset-0 flex items-center justify-center z-20'>
                    <div onClick={fecharModalTexto} className='bg-black bg-opacity-50 fixed h-full w-full z-20'></div>
                    <div className='bg-white rounded-lg min-h-[200px] p-6 w-1/2 z-30'>
                        <div className='relative border border-cor_principal bg-white rounded-md p-2 w-full'>
                            <textarea
                                id="editando-entidade"
                                type="text"
                                className="w-full h-full justify-center items-center outline-none resize-none"
                                value={modalEditar.entidade}
                                onChange={(e) => handleInputChange('entidade', e.target.value)}
                                rows={calcularRows(modalEditar.entidade, 8)}
                                maxLength={250}
                            />
                            <h1 className='absolute top-0 -mt-3 bg-white p-1 text-xs text-cor_principal'>ENTIDADE</h1>
                            {modalEditar.entidade.length > 0 && <h1 className='text-xs text-gray-600 absolute bottom-0'>Máximo de caracteres <span className='text-gray-600'>{modalEditar.entidade.length} de 250</span></h1>}
                        </div>
                        <div className='relative border border-cor_principal bg-white rounded-md p-2 w-full mt-6'>
                            <textarea
                                id="editando-descricao"
                                type="text"
                                className="w-full h-full justify-center items-center outline-none resize-none"
                                value={modalEditar.descricao}
                                onChange={(e) => handleInputChange('descricao', e.target.value)}
                                rows={calcularRows(modalEditar.descricao, 8)}
                                maxLength={750}
                            />
                            <h1 className='absolute top-0 -mt-3 bg-white p-1 text-xs text-cor_principal'>DESCRIÇÃO</h1>
                            {modalEditar.descricao.length > 0 && <h1 className='text-xs text-gray-600 absolute bottom-0'>Máximo de caracteres <span className='text-gray-600'>{modalEditar.descricao.length} de 750</span></h1>}
                        </div>
                        <div className="flex justify-end items-end w-full gap-5">
                            <button onClick={fecharModalTexto}>
                                <h1 className='mt-4 bg-red-600 text-white px-4 py-2 rounded'>FECHAR</h1>
                            </button>
                            <button onClick={atualizaEntidade}>
                                <h1 className='mt-4 bg-cor_principal text-white px-4 py-2 rounded'>ATUALIZAR</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
            <div className="flex flex-col flex-grow">
                <div className="flex flex-col h-auto">
                    <div className='bg-white py-2 px-5 rounded-lg'>
                        <div className="flex flex-row gap-3 mt-2 items-center">
                            <h1 className='font-semibold text-center'>Filtros: </h1>
                            <div className="flex flex-col w-full relative">
                                <input
                                    className="border border-gray-400 bg-gray-300 rounded-md w-full pl-10 py-1 h-9 resize-none focus:outline-none"
                                    placeholder="Busque por entidades"
                                    value={termoBusca}
                                    onChange={(e) => {
                                        setTermoBusca(e.target.value);
                                        setPaginaAtual(1);
                                    }}
                                />
                                <span className="absolute mt-[8px] ml-2 text-gray-400"><FaSearch size={20} /></span>
                            </div>
                        </div>
                        <div className="mt-3 flex flex-row gap-2">                         
                            <div className='relative border border-cor_principal bg-white rounded-md p-2 w-1/2'>
                                <textarea
                                    placeholder='@nova_entidade...'
                                    id='nova-entidade'
                                    type="text"
                                    className="w-full justify-center items-center outline-none resize-none"
                                    value={novaEntidade}
                                    onChange={(e) => setnovaEntidade(e.target.value)}
                                    rows={calcularRows(novaEntidade, 5)}
                                    maxLength={250}
                                />
                                <h1 className='absolute top-0 -mt-3 bg-white p-1 text-xs text-cor_principal'>NOVA ENTIDADE</h1>
                                {novaEntidade.length > 0 && <h1 className='text-xs text-gray-600 absolute bottom-0'>Máximo de caracteres <span className='text-gray-600'>{novaEntidade.length} de 250</span></h1>}
                            </div>
                            <div className='relative border border-cor_principal bg-white rounded-md p-2 w-1/2'>
                                <textarea
                                    id="nova-descricao"
                                    type="text"
                                    className="w-full justify-center items-center outline-none resize-none"
                                    placeholder="Descreva a entidade aqui..."
                                    value={novaDescricao}
                                    onChange={(e) => setnovaDescricao(e.target.value)}
                                    rows={calcularRows(novaDescricao, 5)}
                                    maxLength={750}
                                />
                                <h1 className='absolute top-0 -mt-3 bg-white p-1 text-xs text-cor_principal'>DESCRIÇÃO</h1>
                                {novaDescricao.length > 0 && <h1 className='text-xs text-gray-600 absolute bottom-0'>Máximo de caracteres <span className='text-gray-600'>{novaDescricao.length} de 750</span></h1>}
                            </div>
                            <button id='add-novo-item'
                                className="bg-cor_principal text-white py-1 max-h-12 px-3 rounded-xl flex items-center gap-2 justify-start" 
                                onClick={adicionarItem} >
                                <PiPlus size={20}/>
                            </button>
                        </div>
                        { itensFiltrados.length > 0 ? (
                            <div className="mt-4 rounded-lg overflow-hidden border-[1px] border-cor_principal">
                                <table className="w-full">
                                    <thead className="bg-cor_principal text-white">
                                        <tr>
                                            <th className="py-5">Entidades</th>
                                            <th>Descrição</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {itensFiltrados.reverse().slice((paginaAtual - 1) * itensPorPagina, paginaAtual * itensPorPagina).map(item => (
                                            <tr key={item.id_entidade} className={`${item.ocultado ? 'bg-gray-300' : 'bg-white'}`}>
                                                <td className={`text-start py-2 `}>
                                                    <div className="max-w-[350px] overflow-hidden text-ellipsis flex items-center ml-6">
                                                        <h1 className='bg-cor_principal w-auto text-white font-semibold py-1 px-3 rounded-lg'>
                                                            {item.entidade}
                                                        </h1>
                                                    </div>
                                                </td>
                                                <td className={`ml-3 text-start py-2 truncate`}>
                                                    <div className="max-w-[550px] overflow-hidden text-ellipsis">
                                                        {item.descricao}
                                                    </div>
                                                </td>
                                                <td className="text-center py-2 flex gap-2 justify-center mr-2">
                                                    <button id={`item-${item.id_entidade}-expand`} onClick={() => abrirModalTexto(item)} className="text-blue-500 ml-1">
                                                        <FaPencilAlt color='white' className='bg-cor_principal rounded-full p-1' size={25} />
                                                    </button>
                                                    <button id={`item-${item.id_entidade}-delete`} onClick={() => removerItem(item.id_entidade)} className="text-red-500">
                                                        <PiTrash color='white' className='bg-[#a30002] rounded-full p-1' size={25} />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ):(
                            <div className='flex my-[5%] w-full justify-center items-center font-semibold italic'>
                                <h1>Nenhum item referente a sua busca foi encontrado.</h1>
                            </div>
                        )}
                        <div className="flex justify-center mt-4 gap-2 -ml-12">
                            {paginaAtual > 2 && (
                                <div className=' flex'>
                                    <button onClick={() => setPaginaAtual(1)} className={`px-3 py-1 rounded-full bg-gray-400`}>{1}</button>
                                    <h1 className='ml-2 mt-2 font-semibold'>. . .</h1>
                                </div>
                            )}
                            {paginasVisiveis().map((pagina, index) => (
                                <button key={index} onClick={() => setPaginaAtual(pagina)} className={`px-3 py-1 rounded-full ${pagina === paginaAtual ? 'bg-green-600 text-white' : 'bg-gray-400'}`}>{pagina}</button>
                            ))}
                        </div>                    
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Entidade;