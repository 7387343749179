import React from 'react';
import { FiLinkedin } from 'react-icons/fi';
import { PiInstagramLogo } from 'react-icons/pi';
import { VscGithubAlt } from 'react-icons/vsc';

const MembroEquipe = ({ nome, cargo, descricao, imagem, tecnologias, imagensTecnologias }) => (
  <div className='m-2 bg-white'>
    <img src={imagem} alt={nome} className='w-full h-auto -mt-1' />
    <div className='p-2 mx-2'>
      <h1 className='font-semibold text-lg mt-2'>{nome}</h1>
      <h2 className='text-sm text-gray-700 mt-2'>{cargo}</h2>
      <p className='text-sm text-gray-500 mt-2'>{descricao}</p>
    </div>
    {tecnologias && imagensTecnologias.length > 0 && (
      <div className='m-2 '>
        <h1 className='font-semibold mt-2 text-gray-700 mx-2'>Tecnologias:</h1>
        <div className='flex gap-2 mx-2 my-3'>
          {imagensTecnologias.map((imgSrc, index) => (
            <img key={index} src={imgSrc} alt={`Tecnologia ${index + 1}`} className='w-8 h-8 object-contain' />
          ))}
        </div>
      </div>
    )}
    <div className='flex px-5 pb-2 gap-8 mt-4'>
      <PiInstagramLogo size={20} />
      <FiLinkedin size={20} />
      <VscGithubAlt size={20} />
    </div>
  </div>
);

const Creditos = () => {
  const membrosEquipe = [
    {
      nome: 'Raimundo Valter',
      cargo: 'Coordenador de Inovação',
      descricao: 'Doutor em Inteligência Artificial, dedica-se a promover um Brasil mais tecnológico e produtivo através de inovações.',
      imagem: '/ifce/imagens/Valter.png',
    },
    {
      nome: 'George Ney Almeida',
      cargo: 'Produc Owner',
      descricao: 'Mestre em Matemática e acredita que a verdadeira inovação é impulsionada pela força. Afinal,  “é o fraco que é cruel. A gentileza só pode vir do forte” (Leo Rosten).',
      imagem: '/ifce/imagens/George.png',
    },
    {
      nome: 'Lucas Ribeiro',
      cargo: 'Developer Client-side (Frontend) UI/UX Designer',
      descricao: 'Estudante de Ciências da Computação, com experiência em desenvolvimento de produtos digitais e comprometido em transformar ideias inovadoras em soluções práticas que fazem a diferença.',
      imagem: '/ifce/imagens/Lucas.png',
      tecnologias: true,
      imagensTecnologias: ['/ifce/imagens/figma-logo.png']
    },
    {
      nome: 'Ana Carla Vidal',
      cargo: 'UI/UX Designer',
      descricao: 'Estudante de Ciências da Computação, apaixonado por desenvolver interfaces digitais inovadoras que proporcionem experiências significativas.',
      imagem: '/ifce/imagens/Ana.png',
      tecnologias: true,
      imagensTecnologias: ['/ifce/imagens/figma-logo.png']
    },
    {
      nome: 'Lian Carvalho',
      cargo: 'UI/UX Designer',
      descricao: 'Estudante do ensino médio do integrado em informática, vê a criatividade como a maior ferramenta que temos. “É incrível conseguir resolver problemas apenas com o que se tem à disposição”.',
      imagem: '/ifce/imagens/Lian.png',
      tecnologias: true,
      imagensTecnologias: ['/ifce/imagens/figma-logo.png']
    },
    {
      nome: 'Alysson Henrique',
      cargo: 'Developer Client-side (Frontend)',
      descricao: 'Estudante de Ciências da Computação, dedicado a criar soluções e implementações visuais de software.',
      imagem: '/ifce/imagens/Alysson.png',
      tecnologias: true,
      imagensTecnologias: ['/ifce/imagens/JavaScript-logo.png', '/ifce/imagens/react-logo.png']
    },
    {
      nome: 'Rodrigo Cruz',
      cargo: 'Developer Client-side (Frontend)',
      descricao: 'Estudante de Ciências da Computação, com interesse em pesquisa e comprometido em desenvolver soluções de software para desafios do mundo real.',
      imagem: '/ifce/imagens/Rodrigo.png',
      tecnologias: true,
      imagensTecnologias: ['/ifce/imagens/JavaScript-logo.png', '/ifce/imagens/react-logo.png']
    },
    {
      nome: 'Alexsandro Costa',
      cargo: 'Developer Server-side (Backend)',
      descricao: 'Estudante de Ciências da Computação e desenvolvedor server-side, ciente de que, sem ele, a Ferrari não sai do lugar.',
      imagem: '/ifce/imagens/Alexandro.png',
      tecnologias: true,
      imagensTecnologias: ['/ifce/imagens/image.png', '/ifce/imagens/Django-Logo.png', '/ifce/imagens/image-1.png']
    },
  ];

  return (
    <div className='absolute flex flex-col overflow-y-auto bg-cor_principal rounded-md z-30 m-5 my-14 max-h-[90%] w-96'>
      <h1 className='text-center text-2xl mt-2 text-white'>
        Conheça a equipe <br /> <span className='font-semibold'>ChatIFCE</span>
      </h1>
      <div className='divide-y'>
        {membrosEquipe.map((membro, index) => (
          <MembroEquipe
            key={index}
            nome={membro.nome}
            cargo={membro.cargo}
            descricao={membro.descricao}
            imagem={membro.imagem}
            tecnologias={membro.tecnologias}
            imagensTecnologias={membro.imagensTecnologias || []}
          />
        ))}
      </div>
    </div>
  );
};

export default Creditos;
