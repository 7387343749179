import React, { useState, useEffect, useRef } from 'react';
import { BiCheckDouble } from 'react-icons/bi';
import { PiDot, PiPaperPlaneRightFill } from 'react-icons/pi';
import notificationSound from '../mensagem.mp3';

const TesteDeAtendimento = () => {
  const [mensagens, setMensagens] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [digitando, setDigitando] = useState(false);
  const [, setMsgBoasVindas] = useState(false);
  const mensagensEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const token = localStorage.getItem('token');
  const audio = new Audio(notificationSound);
  const mensagensBoasVindas = [
    "Olá, eu sou o asistente virtual do IFCE! Estou aqui para ajudar e responder a qualquer dúvida que você possa ter sobre o IFCE.",
    "Oi! Eu sou o asistente virtual do IFCE. Como posso te ajudar hoje?",
    "Bem-vindo! Eu sou o asistente virtual do IFCE. Se precisar de qualquer ajuda com o IFCE, estou aqui.",
    "Olá! Sou o asistente virtual do IFCE, seu assistente virtual. Qualquer dúvida sobre o IFCE, pergunte-me!",
    "Oi, sou o asistente virtual do IFCE! Estou aqui para tirar suas dúvidas sobre o IFCE. Como posso ajudar?"
  ];

  useEffect(() => {
    const historicoMensagens = localStorage.getItem('historicoMensagens');
    if (historicoMensagens) {
      setMensagens(JSON.parse(historicoMensagens));
      setMsgBoasVindas(true); 
    } else {
      const mensagemAleatoria = mensagensBoasVindas[Math.floor(Math.random() * mensagensBoasVindas.length)];
      setMensagens([{ remetente: 'Suapinho', texto: mensagemAleatoria, hora: new Date(), status: 'lido' }]);
      setMsgBoasVindas(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    localStorage.setItem('historicoMensagens', JSON.stringify(mensagens));
    if (mensagensEndRef.current) {
      mensagensEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [mensagens]);

  const handleSendMensagem = async () => {
    if (!inputValue.trim()) return;

    const novaMensagem = {
      remetente: 'Você',
      texto: inputValue,
      hora: new Date(),
      status: 'enviado',
    };
    setMensagens([...mensagens, novaMensagem]);
    setInputValue('');
    setDigitando(true);

    const indexUltimaMensagem = mensagens.length;

    try {
      const response = await fetch('https://suapuno.app:8080/api/assistente-virtual/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
          identificador: true,
        },
        body: JSON.stringify({ pergunta: inputValue }),
      });

      if (!response.ok) {
        throw new Error(`Erro de rede: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();

      if (!data.resposta) {
        throw new Error('Resposta inválida da API');
      }

      setMensagens((prevMensagens) => [
        ...prevMensagens,
        { remetente: 'Suapinho', texto: data.resposta, hora: new Date(), status: 'lido' },
      ]);

    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);

      setMensagens((prevMensagens) => [
        ...prevMensagens,
        { remetente: 'Suapinho', texto: 'Desculpe, ocorreu um erro ao processar sua mensagem, tente novamente mais tarde.', hora: new Date(), status: 'lido' },
      ]);
    } finally {
      audio.play();
      atualizarStatusMensagem(indexUltimaMensagem, 'lido');
      setDigitando(false);
    }
  };

  const linkClicavel = (text) => {
    const urlRegex = /https?:\/\/[^\s]+/g;
    return text.split(urlRegex).reduce((prev, current, i) => {
      const match = text.match(urlRegex);
      if (!match) return prev.concat(current);
      return prev.concat(
        <React.Fragment key={i}>
          {current}
          <a href={match[i]} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            {match[i]}
          </a>
        </React.Fragment>
      );
    }, []);
  };

  useEffect(() => {
    mensagensEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [mensagens]);

  const formatarHora = (date) => {
    const horas = date.getHours().toString().padStart(2, '0');
    const minutos = date.getMinutes().toString().padStart(2, '0');
    return `${horas}:${minutos}`;
  };

  const atualizarStatusMensagem = (index, novoStatus) => {
    setMensagens((mensagensAnteriores) => {
      const novasMensagens = [...mensagensAnteriores];
      novasMensagens[index].status = novoStatus;
      return novasMensagens;
    });
  };

  return (
    <div className="flex flex-col justify-between bg-fundo_chat h-[calc(100vh-100px)] relative rounded-xl">
      <div className="top-0 left-0 w-full z-20">
        <div className="flex flex-row bg-cor_principal px-3 py-3 justify-between rounded-t-lg">
            <div className="flex items-center">
                <div className='relative'>
                    <div className=' w-12 h-12 bg-gray-300 rounded-full'></div>
                    <img src='/ifce/imagens/logo_transparente.png' alt='logo' className='h-10 w-10 -mt-11 ml-1 absolute'/>
                </div>
                <div className='flex-col'>
                    <h1 className="ml-2 font-bold text-gray-100">IFCE - Aracati</h1>
                    <h1 className='ml-2 font-semibold text-sm text-gray-200'>PREVIEW</h1>
                </div>
            </div>
        </div>
      </div>
      <div ref={chatContainerRef} className="flex-1 overflow-y-auto p-4" style={{ maxHeight: 'calc(100% - 120px)' }} >
        {mensagens.map((mensagem, index) => (
            <div key={index} className={`flex mb-2 ${mensagem.remetente === 'Você' ? 'justify-end' : 'justify-start'}`}>
              <div className="flex items-start">
                <div className={`p-2 rounded-lg text-sm text-white relative max-w-[80vw] ${ mensagem.remetente === 'Assistente' || mensagem.remetente === 'Suapinho' ? 'bg-chat_cor_1 ml-2' : 'bg-chat_cor_2 mr-2'} ${mensagem.remetente === 'Você' ? '' : 'text-left'}`} style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                  <div style={{ whiteSpace: 'pre-wrap' }}>{linkClicavel(mensagem.texto)}</div>
                  <div className="flex justify-end items-center">
                    <span className={`text-xs text-gray-500 ${mensagem.status === 'enviado' ? 'mr-[1px]':'mr-[2px]'}`}>{formatarHora(new Date(mensagem.hora))}</span>
                    {mensagem.remetente === 'Você' && (
                      mensagem.status === 'enviado' ? <BiCheckDouble size={18} color="#828282" /> :
                      mensagem.status === 'lido' ? <BiCheckDouble size={18} color="#34B7F1" /> : null
                    )}
                  </div>
                  <div className={`${mensagem.remetente === 'Assistente' || mensagem.remetente === 'Suapinho' ? 'absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-r-[12px] border-r-chat_cor_1 rotate-[-40deg] bottom-[-5px] -ml-[15px]' : 'absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-l-[12px] border-l-chat_cor_2 mt-2 right-0 rotate-[40deg] bottom-[-5px] -mr-[7px]' }`} />
                </div>
              </div>
            </div>
          ))}
          {digitando && (
            <div className="flex mb-2 justify-start">
              <div className="flex items-start">
                <div className="p-2 rounded-md text-sm bg-[#2f3033] text-left">
                  <div className="font-bold"></div>
                  <div className='flex -mb-6 -mt-4'>
                    <PiDot color='white' size={60} className='animate-bounce -mr-10 -ml-5' />
                    <PiDot color='white' size={60} className='animate-bounce -mr-10' style={{ animationDelay: '0.2s' }} />
                    <PiDot color='white' size={60} className='animate-bounce -mr-4' style={{ animationDelay: '0.4s' }} />
                  </div>
                </div>
              </div>
            </div>
          )}
        <div ref={mensagensEndRef} />
      </div>
      <div className="p-4 flex flex-col w-full bg-chat_cor_1 rounded-t-xl">
        <div className='flex'>
          <input
            type="text"
            placeholder="Digite uma mensagem..."
            className="w-[100%] mr-6 p-2 pr-[34px] rounded-md bg-chat_cor_3 text-white mb-[3px]"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleSendMensagem();
            }}
          />
          <PiPaperPlaneRightFill size={25} color='black' className="absolute right-0 mr-3 mt-2 bg-cor_principal p-1 rounded-full" onClick={handleSendMensagem} />
        </div>
        <h1 className='text-[10px] text-center italic text-gray-400'>O assistente pode cometer erros. Certifique-se de revisar suas informações</h1>
      </div>
    </div>
  );
};

export default TesteDeAtendimento;
