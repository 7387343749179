import React, { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { PiPlus, PiTrash } from "react-icons/pi";

const ModalSimilares = ({ id_pergunta, pergunta, fecharModal, itensPorPagina, remover }) => {
    const [perguntas, setPerguntas] = useState([]);
    const [linkNext, setLinkNext] = useState('');
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [termoBusca, setTermoBusca] = useState('');
    const [novaPergunta, setNovaPergunta] = useState('');
    const token = localStorage.getItem('token');
    const [itensPorPaginaState, setItensPorPaginaState] = useState(itensPorPagina);

    const handleFecharModal = (event) => {
        if (event.target === event.currentTarget) {
            fecharModal();
        }
    };

    useEffect(() => {
        const fetchSimilares = async () => {
            try {
                const perguntas = await fetch(`https://suapuno.app:8080/api/assistente-virtual/perguntas-similares/${id_pergunta}/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });
                if (perguntas.ok) {
                    const resposta = await perguntas.json();
                    setPerguntas(resposta.results.reverse());
                    setLinkNext(resposta.next);
                } else {
                    console.log('Erro ao obter perguntas similares:', perguntas.status);
                }
            } catch (error) {
                console.log('Erro ao tentar obter perguntas similares.', error);
            }
        };
        fetchSimilares();
    }, [token, id_pergunta]);

    useEffect(() => {
        const calcularItensPorPagina = () => {
            const alturaJanela = (window.innerHeight - 180);
            const alturaElementosFixos = 250; 
            const alturaNovaPergunta = calcularRows(novaPergunta, 5) * 20;
            const alturaDisponivel = alturaJanela - alturaElementosFixos - alturaNovaPergunta;
            const alturaItem = 50; 
            const novosItensPorPagina = Math.floor(alturaDisponivel / alturaItem);
            setItensPorPaginaState(Math.max(novosItensPorPagina, 1)); 
        };

        calcularItensPorPagina();
        window.addEventListener('resize', calcularItensPorPagina);

        return () => window.removeEventListener('resize', calcularItensPorPagina);
    }, [novaPergunta]);

    const itensFiltrados = perguntas.filter(item =>
        item.pergunta.toLowerCase().includes(termoBusca.toLowerCase())
    ).reverse();

    const adicionarItem = async () => {
        if (novaPergunta.trim()) {
            const novoItem = {
                pergunta: novaPergunta,
            };
            try {
                const response = await fetch(`https://suapuno.app:8080/api/assistente-virtual/perguntas-similares/${id_pergunta}/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`,
                    },
                    body: JSON.stringify(novoItem)
                });
                if (!response.ok) {
                    throw new Error('Erro na requisição: ' + response.statusText);
                } else {
                    const perguntasReload = await fetch(`https://suapuno.app:8080/api/assistente-virtual/perguntas-similares/${id_pergunta}/`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Token ${token}`
                        }
                    });
                    if (perguntasReload.ok) {
                        const resposta = await perguntasReload.json();
                        setPerguntas(resposta.results.reverse());
                        setLinkNext(resposta.next);
                        setNovaPergunta('');
                    } else {
                        console.log('Api não retornou o ok:', perguntasReload.status);
                    }
                }
            } catch (error) {
                console.error('Erro ao adicionar pergunta:', error);
            }
        }
    };

    const removerItem = (id_pergunta) => {
        remover(id_pergunta);
        setPerguntas(perguntas.filter(item => item.id_pergunta !== id_pergunta));
    };

    const handleInputChange = (id, field, value) => {
        setPerguntas(perguntas.map(item => item.id_pergunta === id ? { ...item, [field]: value } : item));
    };

    const paginasVisiveis = () => {
        const maisPerguntas = async () => {
            try {
                const perguntas = await fetch(linkNext, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });
                if (perguntas.ok) {
                    const resposta = await perguntas.json();
                    setPerguntas(prevPeticoes => [...resposta.results.reverse(), ...prevPeticoes]);
                    setLinkNext(resposta.next);
                } else {
                    console.log('Api não retornou o ok:', perguntas.status);
                }
            } catch (error) {
                if (!(error instanceof SyntaxError)) {
                    console.log('Erro ao tentar obter mais contextos.', error);
                }
            }
        };

        const paginas = [];

        if (paginaAtual - 1 > 0) {
            paginas.push(paginaAtual - 1);
        }
        paginas.push(paginaAtual);
        if (itensUnicos.slice((paginaAtual - 1) * itensPorPaginaState, paginaAtual * itensPorPaginaState).length === itensPorPaginaState) {
            paginas.push(paginaAtual + 1);
        } else {
            maisPerguntas();
        }

        return paginas;
    };

    const handleKeypress = (e, id_pergunta, resposta) => {
        if (e.key === 'Enter') {
            const itemAtualizado = perguntas.find(item => item.id_pergunta === id_pergunta);
            if (itemAtualizado) {
                atualizaPergunta(itemAtualizado, id_pergunta, resposta);
            }
        }
    };

    const handleBlur = (id_pergunta, resposta) => {
        const itemAtualizado = perguntas.find(item => item.id_pergunta === id_pergunta);
        if (itemAtualizado) {
            atualizaPergunta(itemAtualizado, id_pergunta, resposta);
        }
    };

    const atualizaPergunta = (itemAtualizado, id_pergunta) => {
        const novoItem = {
            pergunta: itemAtualizado.pergunta,
            resposta: itemAtualizado.resposta,
        };
        fetch(`https://suapuno.app:8080/api/assistente-virtual/pergunta-resposta/${id_pergunta}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify(novoItem)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erro na requisição: ' + response.status);
                }
            })
            .catch(error => {
                console.log('Erro ao salvar alterações na pergunta.', error);
            });
    };

    const filtrarDuplicados = (itens) => {
        const idsVistos = new Set();
        return itens.filter(item => {
            if (idsVistos.has(item.id_pergunta)) {
                return false;
            } else {
                idsVistos.add(item.id_pergunta);
                return true;
            }
        });
    };

    const itensUnicos = filtrarDuplicados(itensFiltrados);

    const calcularRows = (texto, limite) => {
        if (texto.split('\n').length < 2) {
            return Math.min((texto.length / 50) + 1, limite);
        } else {
            return Math.min(texto.split('\n').length, limite);
        }
    };

    return (
        <div id='modal' onClick={handleFecharModal} className='flex justify-center h-screen w-screen bg-[#00000093]'>
            <div className='flex-col bg-white p-5 w-full m-10 mx-[22%] rounded-lg'>
                <h1 className='text-4xl font-semibold text-center'>Perguntas/Temas similares</h1>
                <div className="flex flex-col w-full mt-5 relative">
                    <input
                        className="border border-gray-400 bg-gray-300 rounded-md w-full pl-10 py-1 focus:outline-none"
                        placeholder="Busque por perguntas/temas similares..."
                        value={termoBusca}
                        onChange={(e) => {
                            setTermoBusca(e.target.value);
                            setPaginaAtual(1);
                        }}
                    />
                    <span className="absolute top-2 left-2 text-gray-400">
                        <FaSearch size={20} />
                    </span>
                </div>
                <div className="mt-8">
                    <div className="flex flex-row gap-2">
                        <div className='relative border border-cor_principal bg-white rounded-md p-2 w-full'>
                            <textarea
                                type="text"
                                className="w-full outline-none resize-none"
                                value={novaPergunta}
                                onChange={(e) => setNovaPergunta(e.target.value)}
                                rows={calcularRows(novaPergunta, 4)}
                                maxLength={250}
                            />
                            <h1 className='absolute -top-3 left-2 bg-white p-1 text-xs text-cor_principal'>
                                NOVA PERGUNTA/TEMA SIMILAR
                            </h1>
                            {novaPergunta.length > 0 && <h1 className='text-xs text-gray-600 absolute bottom-0'>Máximo de caracteres <span className='text-gray-600'>{novaPergunta.length} de 250</span></h1>}
                        </div>
                        <button
                            className="bg-cor_principal text-white py-1 px-4 max-h-12 rounded-xl flex items-center gap-2 justify-center"
                            onClick={adicionarItem}
                        >
                            <PiPlus size={20} />
                        </button>
                    </div>
                </div>
                <div className="mt-4 rounded-lg overflow-hidden border-[1px] border-cor_principal">
                    <table className="w-full">
                        <thead className="bg-cor_principal text-white">
                            <tr >
                                <th className="w-3/10 text-center py-5">Pergunta/Tema</th>
                                <th className="w-1/10 text-center">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key="-1">
                                <td>
                                    <h1 className='text-center py-2 truncate w-full ml-2'>{pergunta}</h1>
                                </td>
                            </tr>
                            {itensUnicos
                                .slice((paginaAtual - 1) * itensPorPaginaState, paginaAtual * itensPorPaginaState)
                                .map((item) => {
                                    if (    item.pergunta === pergunta) {
                                        return null;
                                    }

                                    return (
                                        <tr key={item.id_pergunta} className='bg-gray-200'>
                                            <td>
                                                <input
                                                    type='text'
                                                    value={item.pergunta}
                                                    onChange={(e) => handleInputChange(item.id_pergunta, 'pergunta', e.target.value)}
                                                    onKeyDown={(e) => handleKeypress(e, item.id_pergunta, item.resposta)}
                                                    onBlur={() => handleBlur(item.id_pergunta, item.resposta)}
                                                    className='text-center py-2 truncate w-full bg-transparent ml-2'
                                                />
                                            </td>
                                            <td className="text-center py-2">
                                                <button className="text-red-500 mx-1" onClick={() => removerItem(item.id_pergunta)}>
                                                    <PiTrash color='white' className='bg-[#a30002] rounded-full p-1' size={25} />
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-center mt-4 gap-2 -ml-12">
                    {paginaAtual > 2 && (
                        <div className=' flex'>
                            <button onClick={() => setPaginaAtual(1)} className={`px-3 py-1 rounded-full bg-gray-400`}>{1}</button>
                            <h1 className='ml-2 mt-2 font-semibold'>. . .</h1>
                        </div>
                    )}
                    {paginasVisiveis().map((pagina, index) => (
                        <button key={index} onClick={() => setPaginaAtual(pagina)} className={`px-3 py-1 rounded-full ${pagina === paginaAtual ? 'bg-green-600 text-white' : 'bg-gray-400'}`}>{pagina}</button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ModalSimilares;
