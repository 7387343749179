import React, { useState, useEffect } from 'react';
import { FaPencilAlt, FaSearch } from 'react-icons/fa';
import { PiEye, PiEyeClosed, PiListBullets, PiPlus, PiTrash } from "react-icons/pi";
import ModalSimilares from './modalSimilares';

const Contextos = () => {
    const [perguntas, setPerguntas] = useState([]);
    const [linkNext, setLinkNext] = useState('');
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [termoBusca, setTermoBusca] = useState('');
    const [statusSelecionado, setStatusSelecionado] = useState('');
    const [, setVariavel] = useState([]);
    const [novaPergunta, setNovaPergunta] = useState('');
    const [novaResposta, setNovaResposta] = useState('');
    const [modalEditar, setModalEditar] = useState(null);
    const token = localStorage.getItem('token');
    const [idPerguntaSelecionada, setIdPerguntaSelecionada] = useState(null);
    const [perguntaSelecionada, setPerguntaSelecionada] = useState(null);
    const [nenhumItem, setNenhumItem] = useState(false);
    const [itensPorPagina, setItensPorPagina] = useState(5);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [termoBuscaEntidade, setTermoBuscaEntidade] = useState('');
    const [ultimoIndexEntidade, setUltimoIndexEntidade] = useState(-1);
    const [posAtual, setPosAtual] = useState();

    const calcularRows = (texto, limite) => {
        if (texto.split('\n').length < 2) {
            return Math.min((texto.length / 50) + 1, limite);
        } else {
            return Math.min(texto.split('\n').length, limite);
        }
    };

    useEffect(() => {
        const calcularItensPorPagina = () => {
            const alturaJanela = window.innerHeight;
            const alturaElementosFixos = 200;
            const alturaNovaPergunta = calcularRows(novaPergunta, 5) * 20; // 20px por linha
            const alturaNovaResposta = calcularRows(novaResposta, 5) * 20; // 20px por linha
            const alturaDisponivel = alturaJanela - alturaElementosFixos - alturaNovaPergunta - alturaNovaResposta;
            const alturaItem = 60; // Altura aproximada de uma linha na tabela
            const novosItensPorPagina = Math.floor(alturaDisponivel / alturaItem);
            setItensPorPagina(Math.max(novosItensPorPagina, 1));
        };

        calcularItensPorPagina();
        window.addEventListener('resize', calcularItensPorPagina);

        return () => window.removeEventListener('resize', calcularItensPorPagina);
    }, [novaPergunta, novaResposta]);

    useEffect(() => {
        fetchDados();
        // eslint-disable-next-line 
    }, [token]);

    const fetchDados = async () => {
        try {
            const perguntas = await fetch('https://suapuno.app:8080/api/assistente-virtual/pergunta-resposta/', {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`
                }
            });
            if (perguntas.ok) {
                const resposta = await perguntas.json();
                setPerguntas(resposta.results.reverse());
                setLinkNext(resposta.next);
            } else {
                console.log('Api não retornou o ok:', perguntas.status);
            }
        } catch (error) {
            if (!(error instanceof SyntaxError)) {
                console.log('Erro ao tentar obter mais contextos.', error);
            }
        }
    };

    const paginasVisiveis = () => {
        const maisPerguntas = async () => {
            if (linkNext != null){
                try {
                    const perguntas = await fetch(linkNext, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Token ${token}`
                        }
                    });
                    if (perguntas.ok) {
                        const resposta = await perguntas.json();
                        setPerguntas(prevPeticoes => [...resposta.results.reverse(), ...prevPeticoes]);
                        setLinkNext(resposta.next);
                        if(itensUnicos.length === 0 && resposta.next === null){
                            setNenhumItem(true);
                        }
                    } else {
                        console.log('Api não retornou o ok:', perguntas.status);
                    }
                } catch (error) {
                    if (!(error instanceof SyntaxError)) {
                        console.log('Erro ao tentar obter mais contextos.', error);
                    }
                }
            }
        };

        const paginas = [];

        if(paginaAtual - 1 > 0){
            paginas.push(paginaAtual - 1);
        }

        paginas.push(paginaAtual);

        if(itensUnicos.slice((paginaAtual - 1) * itensPorPagina, paginaAtual * itensPorPagina).length === itensPorPagina && linkNext){
            paginas.push(paginaAtual + 1);
        } else if (linkNext) {
            maisPerguntas();
        }

        return paginas;
    };

    const adicionarItem = () => {
        if (novaPergunta.trim() && novaResposta.trim()) {
            const novoItem = {
                pergunta: novaPergunta,
                resposta: novaResposta,
            };
            fetch(`https://suapuno.app:8080/api/assistente-virtual/pergunta-resposta/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify(novoItem)
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erro na requisição: ' + response.statusText);
                } else {
                    fetchDados();
                    setNovaPergunta('');
                    setNovaResposta('');
                }
            });
        }
    };
    
    const toggleAtivo = (id_pergunta, resposta) => {
        fetch(`https://suapuno.app:8080/api/assistente-virtual/alterar-visibilidade-contexto/${id_pergunta}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Erro na requisição: ' + response.statusText);
            }
        });
        setPerguntas(perguntas.map(item => item.resposta === resposta ? { ...item, ocultado: !item.ocultado } : item));
    };
    
    const removerItem = (id_pergunta) => {
        fetch(`https://suapuno.app:8080/api/assistente-virtual/deletar-pergunta/${id_pergunta}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Erro na requisição: ' + response.statusText);
            }
        });
        setPerguntas(perguntas.filter(item => item.id_pergunta !== id_pergunta));
    };

    const abrirModalTexto = (item) => {
        setModalEditar(item);
    };

    const fecharModalTexto = () => {
        setModalEditar(null);
    };

    const filtrarRespostasDuplicadas = (itens) => {
        const respostasVistas = new Set();
        return itens.filter(item => {
            if (!respostasVistas.has(item.resposta)) {
                respostasVistas.add(item.resposta);
                return true;
            }
            return false;
        });
    };

    const filtrarDuplicados = (itens) => {
        const idsVistos = new Set();
        return itens.filter(item => {
            if (idsVistos.has(item.id_pergunta)) {
                return false;
            } else {
                idsVistos.add(item.id_pergunta);
                return true;
            }
        });
    };

    const handleInputChange = (field, value) => {
        setModalEditar(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const atualizaPergunta = ( pergunta, resposta, id_pergunta ) => {
        const novoItem = { pergunta, resposta };
        fetch(`https://suapuno.app:8080/api/assistente-virtual/pergunta-resposta/${id_pergunta}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify(novoItem)
        })
        .then(response => {
            if (!response.ok){
                throw new Error('Erro na requisição: '+ response.status);
            } else {
                fetchDados();
                fecharModalTexto();
            }
        })
        .catch(error => {
            console.log('Erro ao salvar alterações na pergunta.', error);
        });
    };

    const filtrarStatus = (statusSelecionado) => {
        setStatusSelecionado(statusSelecionado);
        if(paginaAtual !== 1){
            setPaginaAtual(1);
        }
    };

    const fecharModal = () => {
      setIdPerguntaSelecionada(null);
      setPerguntaSelecionada(null);
    }

    const destacarEntidades = (text) => {
        const parts = text.split(/(@\w+)/g);
        return parts.map((part, i) => 
            part.startsWith('@') 
            ? <span key={i} className="bg-cor_principal text-white rounded-xl font-semibold p-1">{part}</span> 
            : part
        );
    };

    const fetchEntidades = async () => {
        try {
            const response = await fetch('https://suapuno.app:8080/api/assistente-virtual/entidades/', {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                const filteredData = data.filter(entity => entity.entidade.includes(termoBuscaEntidade));
                setModalData(filteredData);
                setShowModal(true);
            } else {
                console.error('Erro ao buscar entidades:', response.status);
            }
        } catch (error) {
            console.error('Erro ao tentar obter entidades.', error);
        }
    };

    const handleKeyUp = (e) => {
        const textoEntidade = e.target.value;
        const index = textoEntidade.lastIndexOf('@');

        if (index !== ultimoIndexEntidade ) {
            const filtro = textoEntidade.slice(index + 1);
            setTermoBuscaEntidade(filtro);
            fetchEntidades(filtro);
            setPosAtual(index)
        } else {
            setShowModal(false);
            setTermoBuscaEntidade('')
        }
    };

    const handleSelectEntity = (entity) => {
        const novoTexto = novaResposta.slice(0, posAtual) + entity.entidade + ' ';
        setNovaResposta(novoTexto);
        setShowModal(false);
        setTermoBuscaEntidade('')
        setUltimoIndexEntidade(posAtual)
    };

    const itensFiltrados = perguntas.filter(item => item.pergunta.toLowerCase().includes(termoBusca.toLowerCase()) || item.resposta.toLowerCase().includes(termoBusca.toLowerCase()))
    .filter(item => statusSelecionado ? item.ocultado === true : true)
    .reverse();

    const itensUnicos = filtrarDuplicados(filtrarRespostasDuplicadas(itensFiltrados));

    return (
        <div className='flex h-fit'>
            { idPerguntaSelecionada && 
                <div className='absolute h-full w-full z-10 -inset-0'>
                    <ModalSimilares 
                        id_pergunta={idPerguntaSelecionada} 
                        pergunta={perguntaSelecionada}
                        fecharModal={fecharModal} 
                        itensPorPagina={itensPorPagina} 
                        paginasVisiveis={paginasVisiveis} 
                        remover={removerItem}
                    />
                </div>
            }
            { modalEditar &&
                <div className='fixed inset-0 flex items-center justify-center z-20 '>
                  <div onClick={fecharModalTexto} className='bg-black bg-opacity-50 fixed h-full w-full z-20'></div>
                  <div className='bg-white rounded-lg min-h-[200px] p-6 w-1/2 z-30'>
                      <div className='relative border border-cor_principal bg-white rounded-md p-2 w-full'>
                          <textarea
                              id="editando-pergunta"
                              type="text"
                              className="w-full h-full justify-center items-center outline-none resize-none"
                              value={modalEditar.pergunta}
                              onChange={(e) => handleInputChange('pergunta', e.target.value)} 
                              rows={calcularRows(modalEditar.pergunta, 8)}
                              maxLength={250}
                          />
                          <h1 className='absolute top-0 -mt-3 bg-white p-1 text-xs text-cor_principal'>PERGUNTA/TEMA</h1>
                          {modalEditar.pergunta.length > 0 && <h1 className='text-xs text-gray-600 absolute bottom-0'>Máximo de caracteres <span className='text-gray-600'>{modalEditar.pergunta.length} de 250</span></h1>}
                      </div>
                      <div className='relative border border-cor_principal bg-white rounded-md p-2 w-full mt-7'>
                          <textarea
                              id="editando-resposta" 
                              type="text"
                              className="w-full h-full justify-center items-center outline-none resize-none"
                              value={modalEditar.resposta}
                              onChange={(e) => handleInputChange('resposta', e.target.value)}
                              rows={calcularRows(modalEditar.resposta, 8)}
                              maxLength={750}
                          />
                          <h1 className='absolute top-0 -mt-3 bg-white p-1 text-xs text-cor_principal'>RESPOSTA/CONTEXTO</h1>
                          {modalEditar.resposta.length > 0 && <h1 className='text-xs text-gray-600 absolute bottom-0'>Máximo de caracteres <span className='text-gray-600'>{modalEditar.resposta.length} de 750</span></h1>}
                      </div>
                      <div className="flex justify-end items-end w-full gap-5">
                          <button onClick={fecharModalTexto}>
                              <h1 className='mt-4 bg-red-600 text-white px-4 py-2 rounded'>FECHAR</h1>
                          </button>
                          <button onClick={() => {atualizaPergunta(modalEditar.pergunta, modalEditar.resposta, modalEditar.id_pergunta)}}>  
                              <h1 className='mt-4 bg-cor_principal text-white px-4 py-2 rounded'>ATUALIZAR</h1>
                          </button>
                      </div>
                  </div>
              </div>           
            }
            <div className="flex flex-col flex-grow">
                <div className="flex flex-col h-auto">
                    <div className='bg-white py-2 px-5 rounded-lg'>
                        <div className="flex flex-row gap-3 mt-2 items-center w-full">
                            <h1 className='font-semibold text-center'>Filtros: </h1>
                            <div className="flex flex-col w-full relative">
                                <input
                                    id="termo-busca"
                                    className="border border-gray-400 bg-gray-300 rounded-md w-full pl-10 py-1 h-9 resize-none focus:outline-none"
                                    placeholder="Busque por perguntas/contextos..."
                                    value={termoBusca}
                                    onChange={(e) => {
                                        setTermoBusca(e.target.value);
                                        setPaginaAtual(1);
                                    }}
                                />
                                <span className="absolute mt-[8px] ml-2 text-gray-400"><FaSearch size={20} /></span>
                            </div>
                            <h1>Variáveis:</h1>
                            <div className="flex flex-col">
                                <select
                                    id="filtro-satatus"
                                    className="border border-gray-400 bg-gray-300 rounded-md w-[100px] py-[5px]"
                                    value={statusSelecionado}
                                    onChange={(e) => filtrarStatus(e.target.value)}
                                >
                                    <option value=""></option>
                                    <option value="Ocultado">Ocultado</option>
                                </select>
                            </div>
                            <button
                                id="remover-filtros"
                                className="bg-red-700 text-white w-56 py-2 rounded-xl text-xs font-semibold"
                                onClick={() => {
                                    setTermoBusca('');
                                    setStatusSelecionado('');
                                    setVariavel([]);
                                    setPaginaAtual(1);
                                }}
                            >
                                REMOVER FILTROS
                            </button>
                        </div>
                        <div className="mt-3 flex flex-row gap-2">
                            <div className='relative border border-cor_principal bg-white rounded-md p-2 w-1/2'>
                                <textarea
                                    placeholder='Digite nova pergunta/tema...'
                                    id="nova-pergunta"
                                    type="text"
                                    className="w-full justify-center items-center outline-none resize-none"
                                    value={novaPergunta}
                                    onChange={(e) => setNovaPergunta(e.target.value)}
                                    rows={calcularRows(novaPergunta, 5)}
                                    maxLength={250}
                                />
                                <h1 className='absolute top-0 -mt-3 bg-white p-1 text-xs text-cor_principal'>NOVA PERGUNTA/TEMA</h1>
                                {novaPergunta.length > 0 && <h1 className='text-xs text-gray-600 absolute bottom-0'>Máximo de caracteres <span className='text-gray-600'>{novaPergunta.length} de 250</span></h1>}
                            </div>
                            <div className='relative border border-cor_principal bg-white rounded-md p-2 w-1/2'>
                                <textarea
                                    id="nova-resposta"
                                    type="text"
                                    className="w-full  justify-center items-center outline-none resize-none"
                                    placeholder="Descreva a resposta/contexto aqui..."
                                    value={novaResposta}
                                    onChange={(e) => setNovaResposta(e.target.value)}
                                    rows={calcularRows(novaResposta, 5)}
                                    onKeyUp={handleKeyUp}
                                    maxLength={750}
                                />
                                <h1 className='absolute top-0 -mt-3 bg-white p-1 text-xs text-cor_principal'>RESPOSTA/CONTEXTO</h1>
                                {novaResposta.length > 0 && <h1 className='text-xs text-gray-600 absolute bottom-0'>Máximo de caracteres <span className='text-gray-600'>{novaResposta.length} de 750</span></h1>}
                                {showModal && (
                                    <div className="absolute bg-white shadow-xl rounded-lg mt-2 p-4 z-10 max-h-[30vh] overflow-auto">
                                        <ul>
                                            {modalData.map((entity) => (
                                                <li
                                                    key={entity.id_entidade}
                                                    className="cursor-pointer hover:bg-gray-200 mb-2"
                                                    onClick={() => handleSelectEntity(entity)}
                                                >
                                                    <span className='bg-cor_principal p-1 rounded-md text-white font-semibold'>{entity.entidade}</span>
                                                </li>                                            
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <button id="add-novo-item"
                                className="bg-cor_principal text-white py-1 max-h-12 px-3 rounded-xl flex items-center gap-2 justify-start" 
                                onClick={adicionarItem} >
                                <PiPlus color='white' size={25} />
                            </button>
                        </div>
                        { itensUnicos.length > 0 ? ( 
                            <div className="mt-4 rounded-lg overflow-hidden border-[1px] border-cor_principal">
                                <table className="w-full">
                                    <thead className="bg-cor_principal text-white">
                                        <tr>
                                            <th></th>
                                            <th className="py-5">Pergunta/Tema</th>
                                            <th>Resposta/Contexto</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {itensUnicos.slice((paginaAtual - 1) * itensPorPagina, paginaAtual * itensPorPagina).map(item => (
                                            <tr key={item.id_pergunta} className={`${item.ocultado ? 'bg-gray-300' : 'bg-white'}`}>
                                                <td className="text-center py-2 flex gap-2 justify-center mr-3">
                                                    <button id={`item-${item.id_pergunta}-details`} onClick={() => (setIdPerguntaSelecionada(item.id_pergunta), setPerguntaSelecionada(item.pergunta))}>
                                                        <PiListBullets color='white' className='bg-cor_principal rounded-full p-1 ml-1' size={25} />
                                                    </button>
                                                </td>
                                                <td className={`text-start py-2 truncate resize-none ${item.ocultado ? 'line-through bg-gray-300' : ''}`}>
                                                    <div className="max-w-[350px] overflow-hidden text-ellipsis">
                                                        {item.pergunta}
                                                    </div>
                                                </td>
                                                <td className={`ml-3 text-start py-2 truncate resize-none ${item.ocultado ? 'line-through bg-gray-300' : ''}`}>
                                                    <div className="max-w-screen-md overflow-hidden text-ellipsis">
                                                        {destacarEntidades(item.resposta)}
                                                    </div>
                                                </td>
                                                <td className="text-center py-2 flex gap-2 justify-center">
                                                    <button id={`item-${item.id_pergunta}-expand`} onClick={() => abrirModalTexto(item)} className="text-blue-500 ml-1">
                                                        <FaPencilAlt color='white' className='bg-cor_principal rounded-full p-1' size={25} />
                                                    </button>
                                                    <button id={`item-${item.id_pergunta}-toggle`} onClick={() => toggleAtivo(item.id_pergunta, item.resposta)} className="text-blue-500 ">
                                                        {!item.ocultado ? (
                                                            <PiEye color='white' className='bg-[#b2a10a] rounded-full p-1' size={25} />
                                                        ) : (
                                                            <PiEyeClosed color='white' className='bg-black rounded-full p-1' size={25} />
                                                        )}
                                                    </button>
                                                    <button id={`item-${item.id_pergunta}-delete`} onClick={() => removerItem(item.id_pergunta)} className="text-red-500">
                                                        <PiTrash color='white' className='bg-[#a30002] rounded-full p-1' size={25} />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ):(
                            <>
                                { nenhumItem ? (
                                    <div className='flex my-[5%] w-full justify-center items-center font-semibold italic'>
                                        <h1>Nenhum item referente a sua busca foi encontrado.</h1>
                                    </div>
                                ):(
                                    <div className='flex mt-4 w-full justify-center items-center'>
                                        <img className='' src='/ifce/imagens/loading.gif' alt='gif_loading'></img>
                                    </div>
                                )}
                            </>
                        )}
                        <div className="flex justify-center mt-4 gap-2 -ml-12">
                            {paginaAtual > 2 && (
                                <div className='flex'>
                                    <button onClick={() => setPaginaAtual(1)} className={`px-3 py-1 rounded-full bg-gray-400`}>{1}</button>
                                    <h1 className='ml-2 mt-2 font-semibold'>. . .</h1>
                                </div>
                            )}
                            {paginasVisiveis().map((pagina, index) => (
                                <button key={index} onClick={() => setPaginaAtual(pagina)} className={`px-3 py-1 rounded-full ${pagina === paginaAtual ? 'bg-green-600 text-white' : 'bg-gray-400'}`}>{pagina}</button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contextos;
