import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './index.css';

import Chat from './root/paginas/chat'
import Painel from './root/paginas/assistente/assistente';

function App() {
  return (
    <main>
      <Routes>
        {/* rota inicial */}
        <Route >
          <Route path='/' element={<Chat />} />
        </Route>

        {/* rota do assistente */}
        <Route>
          <Route path='/painel' element={<Painel />} />
        </Route>

      </Routes>
    </main>
  );
}

export default App;