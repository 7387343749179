import React, { useState } from 'react';
import { PiAt, PiChats, PiListMagnifyingGlass, PiSparkle, PiWarningCircle } from "react-icons/pi";
import { isBrowser } from 'react-device-detect';
import Menu from "../../components/ui/menuTopo";
import Contextos from "./contextos"
import Entidade from "./entidades"
import TesteDeAtendimento from "./testeDeAtendimento"
import Conversas from "./conversas"

const Assistente = () => {
  const [aba, setAba] = useState(1);

  if (!isBrowser) {
    return (
      <div className="flex flex-col items-center justify-center h-full text-center">
        <div className="top-0 left-0 w-full"><Menu /></div>
        <div className='flex flex-col justify-center items-center mt-[calc(50%)]'>
          Site não disponível para dispositivos móveis, por favor acesse de um computador.
          <PiWarningCircle size={100} color='red' />
        </div>
      </div>
    );
  }

  return (
    <div className='flex h-screen relative'>
      <div className="max-w-60 min-w-60 bg-cor_secundaria text-black flex flex-col">
        <div className="logo-area bg-white border-r border-gray-400">
          <img src='/ifce/imagens/logo_fundo.png' alt='logo' className='justify-center items-center mt-2 ml-[28%] h-32'/>
          <div className='flex flex-col items-center justify-center mb-2'>
            <h1 className='font-semibold'>IFCE - Aracati</h1>
            <h1 className='font-semibold text-gray-500 text-sm'>ATENDIMENTO VIRTUAL</h1>
          </div>
        </div>
        <nav className="flex items-center flex-col font-semibold text-sm">
            <div onClick={() => setAba(1)} className={` mt-5 py-2 w-full item-center text-center flex items-center justify-center gap-1 hover:bg-[#38753767] hover:cursor-pointer ${aba === 1 ? 'bg-cor_principal text-white hover:bg-cor_principal' : 'bg-transparent'}`}><PiListMagnifyingGlass size={20}/> CONTEXTOS PADRÕES</div>
            <div onClick={() => setAba(2)} className={` mt-2 py-2 w-full item-center text-center flex items-center justify-center gap-1 hover:bg-[#38753767] hover:cursor-pointer ${aba === 2 ? 'bg-cor_principal text-white hover:bg-cor_principal' : 'bg-transparent'}`}><PiAt size={20}/> ENTIDADES</div>
            <div onClick={() => setAba(3)} className={` mt-2 py-2 w-full item-center text-center flex items-center justify-center gap-1 hover:bg-[#38753767] hover:cursor-pointer ${aba === 3 ? 'bg-cor_principal text-white hover:bg-cor_principal' : 'bg-transparent'}`}><PiChats size={20}/> CONVERSAS</div>
            <div onClick={() => setAba(4)} className={` mt-2 py-2 w-full item-center text-center flex items-center justify-center gap-1 hover:bg-[#38753767] hover:cursor-pointer ${aba === 4 ? 'bg-cor_principal text-white hover:bg-cor_principal' : 'bg-transparent'}`}><PiSparkle size={20}/>TESTE DE ATENDIMENTO</div>
        </nav>
      </div>
      <div className="flex flex-col flex-grow bg-gray-200">
      <div className='flex flex-col h-screen '>
      <div className="top-0 left-0 w-full"><Menu /></div>
      <div className="flex flex-col flex-grow bg-gray-200">
        <div className="flex flex-col h-auto px-10 pt-5">
          { aba === 1 && 
            <Contextos />
          }
          { aba === 2 &&
            <Entidade />
          }
          { aba === 3 &&
            <Conversas />
          }
          { aba === 4 &&
            <TesteDeAtendimento/>
          }
        </div>
      </div>
    </div>
      </div>
    </div>
  );
};

export default Assistente;