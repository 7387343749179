import React, { useEffect, useRef, useState } from 'react';
import { FaPencilAlt, FaSearch } from 'react-icons/fa';
import { IoPersonCircle } from 'react-icons/io5';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Conversas = () => {
    const [termoBusca, setTermoBusca] = useState('');
    const token = localStorage.getItem('token');
    const [chatsNormal, setChatsNormal] = useState([]);
    const [chatsAlerta, setChatsAlerta] = useState([]);
    const [expandidoChat, setExpandidoChat] = useState(null);
    const [mensagens, setMensagens] = useState([]);
    const chatContainerRef = useRef(null);
    const [linkNext, setLinkNext] = useState('');
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [paginaAtualAlertas, setPaginaAtualAlertas] = useState(1);
    const [itensPorPagina, setItensPorPagina] = useState(5);
    const [nenhumItem, setNenhumItem] = useState(false);
    const [modalEditar, setModalEditar] = useState(null);

    useEffect(() => {
        const calcularItensPorPagina = () => {
            const alturaJanela = window.innerHeight;
            const alturaElementosFixos = 200;
            const alturaDisponivel = alturaJanela - alturaElementosFixos;
            const alturaItem = 100;
            const novosItensPorPagina = Math.floor(alturaDisponivel / alturaItem);
            setItensPorPagina(Math.max(novosItensPorPagina, 1));
        };

        calcularItensPorPagina();
        window.addEventListener('resize', calcularItensPorPagina);

        return () => window.removeEventListener('resize', calcularItensPorPagina);
    }, []);

    useEffect(() => {
        fetchDados();
        // eslint-disable-next-line
    }, [token]);

    const calcularRows = (texto, limite) => {
        if (texto.split('\n').length < 2) {
            return Math.min((texto.length / 50) + 1, limite);
        } else {
            return Math.min(texto.split('\n').length, limite);
        }
    };

    const fetchDados = async () => {
        try {
            const response = await fetch('https://suapuno.app:8080/api/assistente-virtual/chat-usuarios/', {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setChatsSeparados([...data.results]);
                setLinkNext(data.next);
            } else {
                console.log('Api não retornou o ok:', response.status);
            }
        } catch (error) {
            console.log('Erro ao tentar capturar chats dos alunos.', error);
        }
    };

    const toggleChat = async (index, id_aluno, id_anonimo) => {
        const expandido = expandidoChat !== index;
        setExpandidoChat(expandido ? index + ((paginaAtual - 1) * itensPorPagina) : null);

        if (expandido) {
            let allMessages = [];
            let nextUrl;
            if (id_aluno) {
                nextUrl = `https://suapuno.app:8080/api/assistente-virtual/listar-mensagens/?id_aluno=${id_aluno}`;
            } else {
                nextUrl = `https://suapuno.app:8080/api/assistente-virtual/listar-mensagens/?identificador=${id_anonimo}`;
            }

            try {
                while (nextUrl) {
                    const response = await fetch(nextUrl, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Token ${token}`
                        }
                    });

                    if (response.ok) {
                        const data = await response.json();
                        allMessages = [...allMessages, ...data.results];
                        nextUrl = data.next;
                    } else {
                        console.log('Api não retornou o ok:', response.status);
                        break;
                    }
                }

                setMensagens(allMessages.reverse());
            } catch (error) {
                if (!(error instanceof SyntaxError)) {
                    console.log('Erro ao tentar capturar mensagens do aluno.', error);
                }
            }
        } else {
            setMensagens([]);
        }
    };

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [mensagens]);

    const setChatsSeparados = (chats) => {
        const normal = [];
        const alerta = [];

        chats.forEach((item) => {
            if (item.quantidade_revisar === 0) {
                normal.push(item);
            } else {
                alerta.push(item);
            }
        });

        setChatsNormal(prevChatsNormal => [...prevChatsNormal, ...normal]);
        setChatsAlerta(prevChatsAlerta => [...prevChatsAlerta, ...alerta]);
    };

    const exibirPaginasNormais = () => {
        const maisPaginasNormais = async () => {
            if (linkNext !== null) {
                try {
                    const resposta = await fetch(linkNext, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Token ${token}`
                        }
                    });
                    if (resposta.ok) {
                        const data = await resposta.json();
                        setChatsSeparados([...data.results]);
                        setLinkNext(data.next);
                        if (chatsNormal.length === 0 && chatsAlerta.length === 0 && data.next === null) {
                            setNenhumItem(true);
                        }
                    } else {
                        console.log('Api não retornou o ok:', resposta.status);
                    }
                } catch (error) {
                    console.log('Erro ao tentar obter mais chats.', error);
                }
            }
        };
    
        const paginas = [];
    
        if (paginaAtual - 1 > 0) {
            paginas.push(paginaAtual - 1);
        }
    
        paginas.push(paginaAtual);
    
        if (chatsVisiveisNormal.slice((paginaAtual - 1) * itensPorPagina, paginaAtual * itensPorPagina).length === itensPorPagina) {
            paginas.push(paginaAtual + 1);
        } else {
            maisPaginasNormais();
        }
    
        return paginas;
    };

    const exibirPaginasAlerta = () => {    
        const paginas = [];
    
        if (paginaAtualAlertas - 1 > 0) {
            paginas.push(paginaAtualAlertas - 1);
        }
    
        paginas.push(paginaAtualAlertas);
    
        if (chatsVisiveisAlerta.slice((paginaAtualAlertas - 1) * itensPorPagina, paginaAtualAlertas * itensPorPagina).length === itensPorPagina) {
            paginas.push(paginaAtualAlertas + 1);
        }
    
        return paginas;
    };

    const chatsVisiveisNormal = chatsNormal.filter(item => item.username.toLowerCase().includes(termoBusca.toLowerCase()));
    const chatsVisiveisAlerta = chatsAlerta.filter(item => item.username.toLowerCase().includes(termoBusca.toLowerCase()));

    const linkClicavel = (text) => {
        const urlRegex = /https?:\/\/[^\s]+/g;
        return text.split(urlRegex).reduce((prev, current, i) => {
            const match = text.match(urlRegex);
            if (!match) return prev.concat(current);
            return prev.concat(
                <React.Fragment key={i}>
                    {current}
                    <a href={match[i]} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                        {match[i]}
                    </a>
                </React.Fragment>
            );
        }, []);
    };

    const abrirModalEditar = (mensagem) => {
        let dados = {
            id_interacao: mensagem.id_interacao,
            pergunta: mensagem.pergunta,
            resposta: '',
            criacao: mensagem.criacao,
            revisar: mensagem.revisar
        }
        setModalEditar(dados);
    }

    const handleInputChange = (field, value) => {
        setModalEditar(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const atualizaPergunta = (pergunta, resposta, id_interacao) => {
        const novoItem = { pergunta, resposta };
        fetch(`https://suapuno.app:8080/api/assistente-virtual/pergunta-resposta/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify(novoItem)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Erro na requisição: ' + response.status);
            } else {
                return fetch(`https://suapuno.app:8080/api/assistente-virtual/revisar_interacoes/${id_interacao}/`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`,
                    },
                    body: JSON.stringify({ revisar: false }) // Atualiza o campo revisar para false
                });
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Erro ao atualizar a interação: ' + response.status);
            } else {
                // Atualiza o estado do componente para ocultar o botão de editar
                setMensagens(prevMensagens =>
                    prevMensagens.map(mensagem =>
                        mensagem.id_interacao === id_interacao
                            ? { ...mensagem, revisar: false }
                            : mensagem
                    )
                );
                fecharModalTexto();
            }
        })
        .catch(error => {
            console.log('Erro ao salvar alterações na pergunta.', error);
        });
    };
    

    const fecharModalTexto = () => {
        setModalEditar(null);
    };

    return (
        <div className='flex h-fit'>
            { modalEditar &&
                <div className='fixed inset-0 flex items-center justify-center z-20 '>
                  <div onClick={fecharModalTexto} className='bg-black bg-opacity-50 fixed h-full w-full z-20'></div>
                  <div className='bg-white rounded-lg min-h-[200px] p-6 w-1/2 z-30'>
                      <div className='relative border border-cor_principal bg-white rounded-md p-2 w-full'>
                          <textarea
                              id="editando-pergunta"
                              type="text"
                              className="w-full h-full justify-center items-center outline-none resize-none"
                              value={modalEditar.pergunta}
                              onChange={(e) => handleInputChange('pergunta', e.target.value)} 
                              rows={calcularRows(modalEditar.pergunta, 8)}
                              maxLength={250}
                          />
                          <h1 className='absolute top-0 -mt-3 bg-white p-1 text-xs text-cor_principal'>PERGUNTA/TEMA</h1>
                          {modalEditar.pergunta.length > 0 && <h1 className='text-xs text-gray-600 absolute bottom-0'>Máximo de caracteres <span className='text-gray-600'>{modalEditar.pergunta.length} de 250</span></h1>}
                      </div>
                      <div className='relative border border-cor_principal bg-white rounded-md p-2 w-full mt-7'>
                          <textarea
                              id="editando-resposta" 
                              type="text"
                              className="w-full h-full justify-center items-center outline-none resize-none"
                              value={modalEditar.resposta}
                              onChange={(e) => handleInputChange('resposta', e.target.value)}
                              rows={calcularRows(modalEditar.resposta, 8)}
                              maxLength={750}
                          />
                          <h1 className='absolute top-0 -mt-3 bg-white p-1 text-xs text-cor_principal'>RESPOSTA/CONTEXTO</h1>
                          {modalEditar.resposta.length > 0 && <h1 className='text-xs text-gray-600 absolute bottom-0'>Máximo de caracteres <span className='text-gray-600'>{modalEditar.resposta.length} de 750</span></h1>}
                      </div>
                      <div className="flex justify-end items-end w-full gap-5">
                          <button onClick={fecharModalTexto}>
                              <h1 className='mt-4 bg-red-600 text-white px-4 py-2 rounded'>FECHAR</h1>
                          </button>
                          <button onClick={() => {atualizaPergunta(modalEditar.pergunta, modalEditar.resposta, modalEditar.id_interacao)}}>  
                              <h1 className='mt-4 bg-cor_principal text-white px-4 py-2 rounded'>ATUALIZAR</h1>
                          </button>
                      </div>
                  </div>
              </div>           
            }
            <div className="flex flex-col flex-grow">
                <div className="flex flex-col h-auto">
                    <div className='bg-white py-2 px-5 rounded-lg'>
                        <div className="flex flex-row gap-3 mt-2 items-center w-full">
                            <h1 className='font-semibold text-center'>Filtros: </h1>
                            <div className="flex flex-col w-full relative">
                                <input
                                    className="border border-gray-400 bg-gray-300 rounded-md w-full pl-10 py-1 focus:outline-none"
                                    placeholder="Busque por conversas"
                                    value={termoBusca}
                                    onChange={(e) => {
                                        setTermoBusca(e.target.value)
                                        setPaginaAtual(1);
                                    }}
                                />
                                <span className="absolute mt-[8px] ml-2 text-gray-400"><FaSearch size={20} /></span>
                            </div>
                        </div>
                        <div className='flex flex-col mt-5'>
                            {chatsVisiveisNormal.length > 0 ? (
                                <>
                                    {expandidoChat === null ? (
                                        <div>
                                            <div className='flex flex-row justify-between mt-5 gap-2'>
                                                <div className="flex flex-col w-1/2">
                                                    <h2 className="font-bold text-center">Chats Normais</h2>
                                                    {chatsVisiveisNormal.slice((paginaAtual - 1) * itensPorPagina, paginaAtual * itensPorPagina).map((item, index) => (
                                                        <div key={index} className='flex flex-col border border-gray-400 rounded-lg mb-2'>
                                                            <div className='flex justify-between items-center p-3 cursor-pointer' onClick={() => toggleChat(index, item.id_usuario, item.identificador)}>
                                                                <div className='flex items-center'>
                                                                    <IoPersonCircle size={50} />
                                                                    <div className='flex flex-col justify-center ml-2'>
                                                                        <h1 className='font-semibold text-xl'>{item.username}</h1>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <FaChevronDown size={20} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <div className="flex justify-center mt-1 gap-2 -ml-12">
                                                        {paginaAtual > 2 && (
                                                            <div className=' flex'>
                                                                <button onClick={() => setPaginaAtual(1)} className="px-3 py-1 rounded-full bg-gray-400">{1}</button>
                                                                <h1 className='ml-2 mt-2 font-semibold'>. . .</h1>
                                                            </div>
                                                        )}
                                                        {exibirPaginasNormais().map((pagina, index) => (
                                                            <button key={index} onClick={() => setPaginaAtual(pagina)} className={`px-3 py-1 rounded-full ${pagina === paginaAtual ? 'bg-green-600 text-white' : 'bg-gray-400'}`}>{pagina}</button>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="flex flex-col w-1/2">
                                                    <h2 className="font-bold text-center">Chats com Alerta</h2>
                                                    {chatsVisiveisAlerta.slice((paginaAtualAlertas - 1) * itensPorPagina, paginaAtualAlertas * itensPorPagina).map((item, index) => (
                                                        <div key={index} className='flex flex-col relative border border-gray-400 rounded-lg mb-2'>
                                                            <div className='flex justify-between items-center p-3 cursor-pointer' onClick={() => toggleChat(index, item.id_usuario, item.identificador)}>
                                                                <div className='flex items-center'>
                                                                    <IoPersonCircle size={50} />
                                                                    <div className='flex flex-col justify-center ml-2'>
                                                                        <h1 className='font-semibold text-xl'>{item.username}</h1>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <FaChevronDown size={20} />
                                                                </div>
                                                            </div>
                                                            <div className='absolute -mt-3 -mr-3 w-5 h-5 flex justify-center items-center text-white text-sm end-0 bg-red-600 rounded-full'>
                                                                {item.quantidade_revisar}
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <div className="flex justify-center mt-1 gap-2 -ml-12">
                                                        {paginaAtualAlertas > 2 && (
                                                            <div className=' flex'>
                                                                <button onClick={() => setPaginaAtualAlertas(1)} className="px-3 py-1 rounded-full bg-gray-400">{1}</button>
                                                                <h1 className='ml-2 mt-2 font-semibold'>. . .</h1>
                                                            </div>
                                                        )}
                                                        {exibirPaginasAlerta().map((pagina, index) => (
                                                            <button key={index} onClick={() => setPaginaAtualAlertas(pagina)} className={`px-3 py-1 rounded-full ${pagina === paginaAtualAlertas ? 'bg-green-600 text-white' : 'bg-gray-400'}`}>{pagina}</button>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div key={expandidoChat} className='flex flex-col w-full border border-gray-400 rounded-lg mb-2 h-[calc(100vh-220px)] overflow-auto'>
                                            <div className='flex justify-between items-center p-3 cursor-pointer h-' onClick={() => toggleChat(expandidoChat)}>
                                                <div className='flex items-center'>
                                                    <IoPersonCircle size={50} />
                                                    <div className='flex flex-col justify-center ml-2'>
                                                        <h1 className='font-semibold text-xl'>{chatsVisiveisNormal[expandidoChat].username}</h1>
                                                        { (chatsVisiveisNormal[expandidoChat].username !== 'Anônimo' && !chatsVisiveisNormal[expandidoChat].username.includes('assistente') ) &&
                                                            <h1 className='italic text-gray-400'>Aluno: {chatsVisiveisNormal[expandidoChat].curso}</h1>
                                                        }
                                                    </div>
                                                </div>
                                                <div>
                                                    <FaChevronUp size={20} />
                                                </div>
                                            </div>
                                            <div ref={chatContainerRef} className="flex-1 overflow-y-auto p-4 bg-fundo_chat rounded-b-lg" >
                                                {mensagens.map((mensagem, index) => (
                                                    <div className='relative' key={index}>
                                                        <div className='flex justify-end'>
                                                            <div className="flex items-start max-w-[90%]">
                                                                <div className="p-2 rounded-lg text-sm text-white relative max-w-[80vw] bg-chat_cor_2 mr-2 text-left" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                                                    <div>{linkClicavel(mensagem.pergunta)}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex items-start my-2 max-w-[90%]">
                                                            <div className="p-2 rounded-lg text-sm text-white relative max-w-[80vw] bg-chat_cor_1 mr-2 text-left" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                                                <div>{mensagem.resposta}</div>
                                                                {mensagem.revisar && 
                                                                    <button onClick={() => (abrirModalEditar(mensagem))} className="absolute -end-8 top-0 h-[100%]" >
                                                                        <FaPencilAlt color='white' className='bg-cor_principal rounded-full p-1' size={25} />
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    {nenhumItem ? (
                                        <div className='flex my-[5%] w-full justify-center items-center font-semibold italic'>
                                            <h1>Nenhum item referente a sua busca foi encontrado.</h1>
                                        </div>
                                    ) : (
                                        <div className='flex mt-4 w-full justify-center items-center'>
                                            <img className='' src='/ifce/imagens/loading.gif' alt='gif_loading'></img>
                                        </div>
                                    )}
                                </>
                            )}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Conversas;