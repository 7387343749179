import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { PiDot, PiX, PiUser, PiLock, PiEye, PiEyeClosed, PiPaperPlaneRightFill } from 'react-icons/pi';
import { FiAlertTriangle } from 'react-icons/fi';
import { BiCheckDouble } from 'react-icons/bi';
import MenuTopo from "../components/ui/menuTopoDeslogado";
import Creditos from './modalCreditos';
import notificationSound from './mensagem.mp3';

const Chat = () => {
  const navigate = useNavigate();
  const [mensagens, setMensagens] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [, setMsgBoasVindas] = useState(false);
  const [digitando, setDigitando] = useState(false);
  const [loginModal, setloginModal] = useState(false);
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [token, setToken] = useState("");
  const [erro, setErro] = useState(false);
  const [creditos, setCreditos] = useState(false);
  const mensagensBoasVindas = [
    "Olá, eu sou o asistente virtual do IFCE! Estou aqui para ajudar e responder a qualquer dúvida que você possa ter sobre o IFCE.",
    "Oi! Eu sou o asistente virtual do IFCE. Como posso te ajudar hoje?",
    "Bem-vindo! Eu sou o asistente virtual do IFCE. Se precisar de qualquer ajuda com o IFCE, estou aqui.",
    "Olá! Sou o asistente virtual do IFCE, seu assistente virtual. Qualquer dúvida sobre o IFCE, pergunte-me!",
    "Oi, sou o asistente virtual do IFCE! Estou aqui para tirar suas dúvidas sobre o IFCE. Como posso ajudar?"
  ];

  const [cookies, setCookies] = useState('');

  useEffect(() => {
    setCookies(localStorage.getItem('statusCookies'));
  }, []);

  const aceitarCookies = () => {
    localStorage.setItem('statusCookies', 'aceito');
    setCookies('aceito');
  };

  const mensagensEndRef = useRef(null);
  const audio = new Audio(notificationSound);

  useEffect(() => {
    const historicoMensagens = localStorage.getItem('historicoMensagens');
    if (historicoMensagens) {
      setMensagens(JSON.parse(historicoMensagens));
      setMsgBoasVindas(true); 
    } else {
      const mensagemAleatoria = mensagensBoasVindas[Math.floor(Math.random() * mensagensBoasVindas.length)];
      setMensagens([{ remetente: 'Assistente', texto: mensagemAleatoria, hora: new Date(), status: 'lido' }]);
      setMsgBoasVindas(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    localStorage.setItem('historicoMensagens', JSON.stringify(mensagens));
    if (mensagensEndRef.current) {
      mensagensEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [mensagens]);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch('https://suapuno.app:8080/api-token-auth/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: "suapinho",
            password: "senhaFRACA"
          })
        });

        if (response.ok) {
          const api = await response.json();
          setToken(api.token);
        } else {
          console.error('Api não retornou o ok:', response.status);
        }
      } catch (error) {
        console.error('Erro ao tentar obter token de chat generico:', error);
      }
    };

    fetchToken();
  }, []);

  const linkClicavel = (text) => {
    const urlRegex = /https?:\/\/[^\s]+/g;
    return text.split(urlRegex).reduce((prev, current, i) => {
      const match = text.match(urlRegex);
      if (!match) return prev.concat(current);
      return prev.concat(
        <React.Fragment key={i}>
          {current}
          <a href={match[i]} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            {match[i]}
          </a>
        </React.Fragment>
      );
    }, []);
  };

  const atualizarStatusMensagem = (index, novoStatus) => {
    setMensagens((mensagensAnteriores) => {
      const novasMensagens = [...mensagensAnteriores];
      novasMensagens[index].status = novoStatus;
      return novasMensagens;
    });
  };

  const handleSendMensagem = async () => {
    const novaMensagem = {
      remetente: 'Você',
      texto: inputValue,
      hora: new Date(),
      status: 'enviado',
    };
    setMensagens([...mensagens, novaMensagem]);
    setInputValue('');
    setDigitando(true);

    const indexUltimaMensagem = mensagens.length;

    try {
      const response = await fetch('https://suapuno.app:8080/api/assistente-virtual/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        credentials: 'include',
        body: JSON.stringify({ pergunta: inputValue }),
      });

      if (!response.ok) {
        throw new Error(`Erro de rede: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();

      if (!data.resposta) {
        throw new Error('Resposta inválida da API');
      }

      setMensagens((prevMensagens) => [
        ...prevMensagens,
        { remetente: 'Suapinho', texto: data.resposta, hora: new Date(), status: 'lido' },
      ]);

    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);

      setMensagens((prevMensagens) => [
        ...prevMensagens,
        { remetente: 'Suapinho', texto: 'Desculpe, ocorreu um erro ao processar sua mensagem, tente novamente mais tarde.', hora: new Date(), status: 'lido' },
      ]);
    } finally {
      audio.play();
      atualizarStatusMensagem(indexUltimaMensagem, 'lido');
      setDigitando(false);
    }
  };  

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/painel');
    }
  }, [navigate]);

  const formatarHora = (date) => {
    const horas = date.getHours().toString().padStart(2, '0');
    const minutos = date.getMinutes().toString().padStart(2, '0');
    return `${horas}:${minutos}`;
  };

  async function onSubmit(event) {
    event.preventDefault();
    try {
      const resposta = await fetch('https://suapuno.app:8080/api-token-auth/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: login,
          password: senha
        })
      });
      if (resposta.ok) {
        const api = await resposta.json();

        localStorage.setItem('token', api.token);
        localStorage.setItem('id_user', api.id_user);

        navigate('/painel');
      } else {
        setErro(true);
      }
    } catch (error) {
      console.error('Não foi possível obter dados de login - ', error);
    }
  }

  return (
    <div className="flex flex-col h-screen">
      {creditos && 
        <div onClick={() => setCreditos(false)} className='w-full h-full bg-[#0000007e] absolute z-30 flex items-center justify-center'>
          <Creditos/>
        </div>
      }
      {cookies === null && 
        <div className='w-full h-full bg-[#0000007e] absolute z-10 flex items-end'>
          <div className='bg-white py-3 px-6 m-5 rounded-lg flex flex-col w-full items-center justify-center'>
            <h1 className='mt-2 italic'>Utilizamos cookies para tornar suas interações com nosso assistente mais relevantes. <br/><p className='mt-2'>Eles nos ajudam a entender a adaptar o conteúdo para você.</p></h1>
            <button className='bg-cor_principal text-white px-7 py-2 rounded-md my-2 font-semibold' onClick={aceitarCookies}>ACEITAR</button>
          </div>
        </div>
      }
      {loginModal && 
        <form onSubmit={onSubmit}>
          <div className='bg-[#00000086] absolute h-full w-full z-20 flex items-center justify-center'>
            <div className='flex flex-col bg-white rounded-lg p-3'>
              <div className='flex items-center justify-end'>
                <PiX onClick={() => setloginModal(false)} size={30}/>
              </div>

              <h1 className='text-center my-1 font-semibold'>Login</h1>

              <div>
                <div className="relative mb-4">
                  <PiUser className="absolute left-2 top-3" color={erro ? "red" : "black"} size={20}/>
                  <input 
                    type="text" 
                    value={login} 
                    onChange={(e) => setLogin(e.target.value)} 
                    className={`border-b-2 bg-transparent pl-8 p-2 w-full text-black ${ erro ? 'placeholder-red-500 border-red-500' : 'placeholder-black border-black'}`} 
                    placeholder="Usuário"
                  />
                </div>
                
                <div>
                  <div className="relative mb-4 flex items-center">
                    <PiLock className="absolute left-2 top-3" color={erro ? "red" : "black"} size={20}/>
                    <input 
                      type={mostrarSenha ? "text" : "password"} 
                      value={senha} 
                      onChange={(e) => setSenha(e.target.value)} 
                      className={`border-b-2 bg-transparent pl-8 p-2 w-full text-black ${ erro ? 'placeholder-red-500 border-red-500' : 'placeholder-black border-black'}`} 
                      placeholder="Senha"
                    />
                    <button 
                      type="button" 
                      onClick={() => setMostrarSenha(!mostrarSenha)} 
                      className="absolute right-2 top-2 text-black"
                    >
                      {mostrarSenha ? <PiEye color={erro ? "red" : "black"}/> : <PiEyeClosed color={erro ? "red" : "black"}/>}
                    </button>
                  </div>
                </div>

                {erro && (
                  <div className="flex flex-row items-center justify-center my-2">
                    <FiAlertTriangle className="text-red-500" />
                    <p className="text-red-500 text-sm ml-1">Usuário ou senha inválidos</p>
                  </div>
                )}
              </div>
              <button type='submit' className='bg-green-700 p-2 rounded-lg font-semibold text-white'>
                PROSSEGUIR
              </button>
            </div>
          </div>
        </form>
      } 
      <div className="bg-fixed top-0 left-0 w-full z-10">
        <div onClick={() => setCreditos(true)} className='w-[25%] h-20 bg-transparent absolute'></div>
        <MenuTopo setloginModal={setloginModal} />
      </div>
      <div className="flex-1 overflow-y-auto p-4 bg-fundo_chat h-fit mb-16">
        {mensagens.map((mensagem, index) => (
          <div key={index} className={`flex mb-2 ${mensagem.remetente === 'Você' ? 'justify-end' : 'justify-start'}`}>
            <div className="flex items-start">
              <div className={`p-2 rounded-lg text-sm text-white relative max-w-[80vw] ${mensagem.remetente === 'Assistente' || mensagem.remetente === 'Suapinho' ? 'bg-chat_cor_1 ml-2' : 'bg-chat_cor_2 mr-2'} ${mensagem.remetente === 'Você' ? '' : 'text-left'}`} style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                <div style={{ whiteSpace: 'pre-wrap' }}>{linkClicavel(mensagem.texto)}</div>
                <div className="flex justify-end items-center">
                  <span className={`text-xs text-gray-500 ${mensagem.status === 'enviado' ? 'mr-[1px]':'mr-[2px]'}`}>{formatarHora(new Date(mensagem.hora))}</span>
                  {mensagem.remetente === 'Você' && (
                    mensagem.status === 'enviado' ? <BiCheckDouble size={18} color="#828282" /> :
                    mensagem.status === 'lido' ? <BiCheckDouble size={18} color="#34B7F1" /> : null
                  )}
                </div>
                <div className={`${mensagem.remetente === 'Assistente' || mensagem.remetente === 'Suapinho' ? 'absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-r-[12px] border-r-chat_cor_1 rotate-[-40deg] bottom-[-5px] -ml-[15px]' : 'absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-l-[12px] border-l-chat_cor_2 mt-2 right-0 rotate-[40deg] bottom-[-5px] -mr-[7px]' }`} />
              </div>
            </div>
          </div>
        ))}
        {digitando && (
          <div className="flex mb-2 justify-start">
            <div className="flex items-start">
              <div className="p-2 rounded-md text-sm bg-chat_cor_1 text-left">
                <div className="font-bold"></div>
                <div className='flex -mb-6 -mt-4'>
                  <PiDot color='white' size={60} className='animate-bounce -mr-10 -ml-5' />
                  <PiDot color='white' size={60} className='animate-bounce -mr-10' style={{ animationDelay: '0.2s' }} />
                  <PiDot color='white' size={60} className='animate-bounce -mr-4' style={{ animationDelay: '0.4s' }} />
                </div>
              </div>
            </div>
          </div>
        )}
        <div ref={mensagensEndRef} />
      </div>
      <div className="fixed bottom-0 p-3 flex flex-col w-full bg-chat_cor_1 rounded-t-xl">
        <div className='flex'>
          <textarea
            type="text"
            placeholder="Digite uma mensagem..."
            className="w-[100%] mr-8 p-2 pr-[34px] rounded-md bg-chat_cor_3 text-white mb-[3px] resize-none focus:outline-none"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter'){
                e.preventDefault();
                handleSendMensagem();
              } 
            }}
            rows={1}
          />
          <PiPaperPlaneRightFill size={25} color='black' className="absolute right-0 mr-3 mt-2 bg-cor_principal p-1 rounded-full" onClick={handleSendMensagem} />
        </div>
        <h1 className='text-[10px] text-center italic text-gray-400'>O assistente pode cometer erros. Certifique-se de revisar suas informações</h1>
      </div>
    </div>
  );
};

export default Chat;