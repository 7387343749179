import { isMobile } from "react-device-detect";
import { PiGear } from "react-icons/pi";

const MenuTopo = ({setloginModal}) => {

  const setModal = () => {
    setloginModal(true)
  }

  return (
    <div className="flex flex-row bg-cor_principal px-3 justify-between">
      <div className="flex items-center w-full">
        <img src='/ifce/imagens/logo_2.png' alt="logo"/>
            {isMobile ? (
                <div className="flex flex-col w-full -ml-16">
                  <h1 className="font-roboto text-xl font-semibold text-white text-center ">IFCE - Aracati</h1>
                  <h1 className="font-roboto text-lg text-white text-center">Atendimento virtual</h1>
                </div>
              ):( 
                <div className="flex flex-col">
                  <h1 className="font-roboto text-2xl font-semibold text-white">IFCE - Aracati</h1>
                  <h1 className="font-roboto text-2xl text-white">Atendimento virtual</h1>
                </div>
              )
            }     
      </div>
      {!isMobile && 
      <div onClick={() => setModal()}>
        <div className="mt-7 mr-3">
          <PiGear size={40} color="white"/>
        </div>
      </div>}
    </div>
  );
}

export default MenuTopo;
